import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../hooks/useAuth';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

function PrivateRoute({ children, ...rest }) {
  let { isLoggedIn } = useAuth();
  const isLoadingUser = useSelector((state) => state.user.isLoadingUser);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        const { token, ...search } = queryString.parse(window.location.search);
        return isLoggedIn || isLoadingUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              search:
                '?next=' +
                encodeURIComponent(
                  location.pathname + '?' + queryString.stringify(search)
                ) +
                (token ? '&token=' + token : ''),
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

export default PrivateRoute;
