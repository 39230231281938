import { sygnet } from './sygnet';
import { logo } from './logo';
import { logoNegative } from './logo-negative';
import './fontawesome';

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilUser,
  cilLockLocked,
  cilAccountLogout,
  cilGroup,
  cilSpreadsheet,
  cilBriefcase,
  cilX,
  cilDollar,
  cilAddressBook,
  cilImage,
  cilFile,
  cilSearch,
  cilFrown,
  cilEyedropper,
  cilChevronBottom,
  cilChevronTop,
  cilHospital,
  cilFolderOpen,
  cilChartPie,
  cilFilter,
  cilChartLine,
  cilOptions,
  cilSettings,
  cilMoney,
  cilChevronDoubleRight,
  cilTrash,
  cibCcDinersClub,
  cibCcAmex,
  cibCcDiscover,
  cibCcJcb,
  cibCcMastercard,
  cibCcVisa,
} from '@coreui/icons';

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilUser,
    cilLockLocked,
    cilAccountLogout,
    cilGroup,
    cilBriefcase,
    cilSpreadsheet,
    cilX,
    cilDollar,
    cilAddressBook,
    cilImage,
    cilFile,
    cilSearch,
    cilFrown,
    cilEyedropper,
    cilChevronBottom,
    cilChevronTop,
    cilHospital,
    cilFolderOpen,
    cilChartPie,
    cilFilter,
    cilChartLine,
    cilOptions,
    cilSettings,
    cilMoney,
    cilChevronDoubleRight,
    cilTrash,
    cibCcDinersClub,
    cibCcAmex,
    cibCcDiscover,
    cibCcJcb,
    cibCcMastercard,
    cibCcVisa,
  }
);
