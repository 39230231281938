import { post, destroy, get } from '../util/request';

export const login = async (user) => {
  const res = await post(`/v1/login`, { user });
  return res.data;
};

export const logout = async () => {
  const res = await destroy('/v1/logout');

  return res.data;
};

export const fetchSession = async () => {
  const res = await get(`/v1/users/users_permissions`);
  return res.data;
};

const Auth = { fetchSession, login, logout };

export default Auth;
