import {
  isNil,
  mergeWith,
  camelCase,
  snakeCase,
  isObject as _isObject,
  isArray as _isArray,
} from 'lodash';

const isArray = (a) => {
  return Array.isArray(a);
};

const isObject = (o) => {
  return (
    o === Object(o) &&
    !isArray(o) &&
    typeof o !== 'function' &&
    !(o instanceof File) &&
    !(o instanceof Date)
  );
};

const transformKeys = (o, transform) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      /**
       * We check if the key start with `_` to avoid break some Rails
       * functionality with keys that start with that character, like `_destroy`
       */
      const newKey = k.startsWith('_') ? k : transform(k);
      n[newKey] = transformKeys(o[k], transform);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return transformKeys(i, transform);
    });
  }

  return o;
};

export const keysToCamel = (o) => {
  return transformKeys(o, camelCase);
};

export const keysToSnakeCase = (o) => {
  return transformKeys(o, snakeCase);
};

const nilMerge = (a, b) => (isNil(a) ? b : a);

export const nilMergeDeep = (a, b) =>
  _isObject(a) && !_isArray(a)
    ? mergeWith({}, a, b, nilMergeDeep)
    : nilMerge(a, b);
