import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAuthToken as setAuthTokenRequest,
  deleteAuthToken,
} from '../util/request';
import { reset, setUser } from '../slices/userSlice';
import Auth from '../api/auth';

export function useAuth() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [isLogginIn, setIsLogginIn] = useState(false);

  const getPersistedToken = () => {
    const token = window.localStorage.getItem('authTK');
    return token;
  };

  const persistToken = (token) => {
    if (token) {
      return window.localStorage.setItem('authTK', token);
    }

    window.localStorage.removeItem('authTK');
  };

  const setAuthToken = (token) => {
    setAuthTokenRequest(token);
    persistToken(token);
  };

  const login = useCallback(
    async (credentials) => {
      setIsLogginIn(true);
      try {
        const { user, token } = await Auth.login(credentials);
        setAuthToken(token);
        setIsLogginIn(false);
        dispatch(setUser(user));
      } catch (error) {
        setIsLogginIn(false);
        persistToken(null);
        throw error;
      }
    },
    [dispatch, setIsLogginIn]
  );

  const logout = () => {
    persistToken(null);
    deleteAuthToken();
    dispatch(reset());
  };

  return {
    isLoggedIn,
    isLogginIn,
    login,
    logout,
    getPersistedToken,
    setAuthToken,
  };
}

export default useAuth;
