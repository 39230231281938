import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import PrivateRoute from './containers/PrivateRoute';
import { useAuth } from './hooks/useAuth';
import { fetchUser } from './slices/userSlice';
import { useDispatch } from 'react-redux';
import { setCodeErrorHandler } from './util/request';
import AppAlert from 'src/containers/AppAlert';
import { SnackbarProvider } from 'notistack';
import queryString from 'query-string';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(() => import('./containers/login/Login'));

const App = () => {
  const { logout, getPersistedToken, setAuthToken } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    setCodeErrorHandler(401, logout);

    let token = queryString.parse(window.location.search).token;
    if (token || (token = getPersistedToken())) {
      setAuthToken(token);
      dispatch(fetchUser());
    }
  });

  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <SnackbarProvider />
          <Switch>
            <Route
              path="/login"
              name="Login"
              render={(props) => <Login {...props} />}
            />
            <PrivateRoute path="/">
              <TheLayout />
            </PrivateRoute>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
      <AppAlert />
    </>
  );
};

export default App;
